export enum Routes {
  Admin = '/admin',

  Auth = '/auth',
  AuthVerifyEmail = '/auth/verify-email',
  AuthRequestPasswordReset = '/auth/request-password-reset',

  Dashboard = '/',
  HowToPage = '/howto/[pageId]',
  Login = '/login',
  MyProjects = '/my-projects',
  New = '/new',
  Onboarding = '/onboarding',

  UserProfile = '/user-profile',
  UserProfileEdit = '/user-profile/edit',
  UserProfileCreation = '/user-profile/creation',
  Project = '/project',
  ProjectNew = '/project/new',
  ProjectDetail = '/project/[projectId]',
  ProjectEdit = '/project/[projectId]/edit',

  Registration = '/registration',
  Specialists = '/specialists',
  SpecialOffers = '/special-offers',
  Success = '/success',

  TaskDetail = '/task/[taskId]',

  AiAdviser = '/ai-adviser',
}
