import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const LogoutIcon: FC<IconSVGProps> = ({ width = 25, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="logout, leave, door">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 4.5C5.55964 4.5 5 5.05964 5 5.75L5 18.25C5 18.9404 5.55964 19.5 6.25 19.5H11.75C12.1642 19.5 12.5 19.8358 12.5 20.25C12.5 20.6642 12.1642 21 11.75 21H6.25C4.73122 21 3.5 19.7688 3.5 18.25L3.5 5.75C3.5 4.23122 4.73122 3 6.25 3L11.75 3C12.1642 3 12.5 3.33579 12.5 3.75C12.5 4.16421 12.1642 4.5 11.75 4.5L6.25 4.5ZM15.7197 6.96967C16.0126 6.67678 16.4874 6.67678 16.7803 6.96967L21.2803 11.4697C21.5732 11.7626 21.5732 12.2374 21.2803 12.5303L16.7803 17.0303C16.4874 17.3232 16.0126 17.3232 15.7197 17.0303C15.4268 16.7374 15.4268 16.2626 15.7197 15.9697L18.9393 12.75L9.5 12.75C9.08579 12.75 8.75 12.4142 8.75 12C8.75 11.5858 9.08579 11.25 9.5 11.25L18.9393 11.25L15.7197 8.03033C15.4268 7.73744 15.4268 7.26256 15.7197 6.96967Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
