import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const AvatarIcon: FC<IconSVGProps> = ({ width = 24, height = 25 }) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.955566" width="24" height="24" rx="12" fill="#E6ECF4" />
    <path
      d="M14.2501 9.65527C14.2501 10.8979 13.2427 11.9053 12.0001 11.9053C10.7574 11.9053 9.75009 10.8979 9.75009 9.65527C9.75009 8.41263 10.7574 7.40527 12.0001 7.40527C13.2427 7.40527 14.2501 8.41263 14.2501 9.65527Z"
      stroke="black"
      strokeWidth="0.9"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 13.7053C9.9654 13.7053 8.42168 14.9139 7.78957 16.623C7.54429 17.2862 8.11108 17.9053 8.81819 17.9053H15.182C15.8891 17.9053 16.4559 17.2862 16.2106 16.623C15.5785 14.9139 14.0348 13.7053 12.0001 13.7053Z"
      stroke="black"
      strokeWidth="0.9"
      strokeLinejoin="round"
    />
  </svg>
);
