import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const OpenNew: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 10.75V5.75C20.75 4.64543 19.8546 3.75 18.75 3.75H6.25C5.14543 3.75 4.25 4.64543 4.25 5.75V18.25C4.25 19.3546 5.14543 20.25 6.25 20.25H11.25M14.6879 20.8366L12.4746 12.5871C12.3748 12.2152 12.7152 11.8748 13.0871 11.9746L21.3366 14.1879C21.7665 14.3032 21.8428 14.8805 21.4576 15.1035L17.8654 17.1832C17.7898 17.227 17.727 17.2898 17.6832 17.3654L15.6035 20.9576C15.3805 21.3428 14.8032 21.2665 14.6879 20.8366Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
