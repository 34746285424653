import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const SaleCouponsIcon: FC<IconSVGProps> = ({
  width = 25,
  height = 24,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 8L8.5 16M21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 9C8.75 9.41421 9.08579 9.75 9.5 9.75C9.91421 9.75 10.25 9.41421 10.25 9C10.25 8.58579 9.91421 8.25 9.5 8.25C9.08579 8.25 8.75 8.58579 8.75 9Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 15C14.75 15.4142 15.0858 15.75 15.5 15.75C15.9142 15.75 16.25 15.4142 16.25 15C16.25 14.5858 15.9142 14.25 15.5 14.25C15.0858 14.25 14.75 14.5858 14.75 15Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
