import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const AiAdviserIcon: FC<IconSVGProps> = ({
  width = 27,
  height = 25,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="#FFA200" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 20.6944 11.3056 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16Z"
      fill="#141125"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5506 16.0124C21.961 16.0688 22.2479 16.4471 22.1916 16.8575C21.8113 19.6257 19.621 21.8144 16.8521 22.1923C16.4416 22.2483 16.0635 21.961 16.0075 21.5506C15.9515 21.1402 16.2388 20.7621 16.6492 20.7061C18.7511 20.4192 20.4169 18.7547 20.7055 16.6534C20.7619 16.243 21.1403 15.956 21.5506 16.0124Z"
      fill="#141125"
    />
    <path
      d="M14 13.25C14 13.9404 13.4404 14.5 12.75 14.5C12.0596 14.5 11.5 13.9404 11.5 13.25C11.5 12.5596 12.0596 12 12.75 12C13.4404 12 14 12.5596 14 13.25Z"
      fill="#141125"
    />
    <path
      d="M19 13.25C19 13.9404 18.4404 14.5 17.75 14.5C17.0596 14.5 16.5 13.9404 16.5 13.25C16.5 12.5596 17.0596 12 17.75 12C18.4404 12 19 12.5596 19 13.25Z"
      fill="#141125"
    />
    <path
      d="M35.2175 32.9555L24.8909 31.7892L31.0643 23.4292L35.2175 32.9555Z"
      fill="#FFA200"
    />
  </svg>
);
