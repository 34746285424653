import { FC, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { AppBar, Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { colors } from '../../../styles/colors';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileMenu } from './MobileMenu';
import { ProjectLogo } from './ProjectLogo';
import { Routes } from '../../../enums/routes';
import { useRouter } from 'next/router';
import { AiAdviserIcon } from '../../../components/Icon/Icons/AiAdviser.icon';
import { spacings } from '../../../styles/spacings';

export const Navbar: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useRouter();

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  const isButtonHidden = [
    Routes.Login,
    Routes.Registration,
    Routes.AuthRequestPasswordReset,
  ].includes(pathname as Routes);

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Link href="/" legacyBehavior>
          <a>
            <Box maxWidth={'120px'} maxHeight={'80%'} height={'100%'}>
              <ProjectLogo />
            </Box>
          </a>
        </Link>
        <StyledContainer>
          <Link href={Routes.AiAdviser} className="ai-button">
            <AiAdviserIcon width={36} height={32} />
          </Link>

          {!isButtonHidden && (
            <MobileMenuButton
              isMenuOpen={isMenuOpen}
              toggleIsMenuOpen={toggleIsMenuOpen}
            />
          )}
        </StyledContainer>
      </StyledToolbar>
      <MobileMenu isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
    </StyledAppBar>
  );
};

export const StyledAppBar = styled(AppBar)`
  height: 50px;
  background: ${colors.brand};

  z-index: 50;
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 100%;
  justify-content: space-between;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.px16};
`;
