import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const FoldersIcon: FC<IconSVGProps> = ({ width = 25, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 5.75V17.25C3.25 18.3546 4.14543 19.25 5.25 19.25H19.75C20.8546 19.25 21.75 18.3546 21.75 17.25V8.75C21.75 7.64543 20.8546 6.75 19.75 6.75H13.5704C12.9017 6.75 12.2772 6.4158 11.9063 5.8594L11.0937 4.6406C10.7228 4.0842 10.0983 3.75 9.42963 3.75H5.25C4.14543 3.75 3.25 4.64543 3.25 5.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
